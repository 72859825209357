<template>
  <div class="latest-incidents-wrapper">
    <div class="title-wrapper">
      <div class="left">
        <h2 class="title">Incidents</h2>
      </div>
      <div class="right">
        <a-button
          class="button-margin-left btn-rounded"
          type="primary"
          icon="plus"
          @click.prevent="$router.push('/call-for-help')"
          >New</a-button
        >
      </div>
    </div>
    <div class="latest-incidents">
      <a-spin v-if="isLoading"></a-spin>

      <!-- Loaded -->
      <div v-else>
        <incident-list-item
          v-for="incident in latestIncidents"
          :incident="incident"
          :key="incident.id"
          :hide-pin="true"
        ></incident-list-item>

        <div class="no-results" v-if="latestIncidents.length == 0">
          No incidents to show
        </div>
      </div>
      <!-- / Loaded -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IncidentListItem from "../Incidents/IncidentListItem.vue";
export default {
  components: { IncidentListItem },
  computed: {
    ...mapGetters("incidents", {
      isLoading: "isLoading",
      incidentsOrdered: "incidentsOrdered",
    }),

    latestIncidents() {
      return this.incidentsOrdered.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.latest-incidents-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title-wrapper {
    flex-shrink: 1;
    display: flex;
    .left {
      flex-grow: 1;
    }
    .right {
      flex-shrink: 1;
    }
  }

  .ant-btn-primary {
        background: #3A0BA3;

        &:hover {
            background: #6031C9;
        }
  }


  .latest-incidents {
    flex-grow: 1;
    background: #fff;
    padding: 5px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 15px;
  }

  .no-results {
    padding: 15px;
  }
}
</style>