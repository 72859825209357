<template>
  <div class="incident-type-option" :class="{'lightspeed': lightspeed == true, 'sims': sims == true, 'in-widget': inWidget == true}" @click.prevent="handleOptionSelected">
    <div class="inner-wrapper">
      <div class="left">
        {{ incidentType.displayName }}
      </div>
      <div class="right">
        <span class="sos" v-if="sos">SOS</span>
        <span v-else>{{ categoryDisplayName }}</span>
      </div>
    </div>

    <a-modal v-model="modalVisible" :title="incidentType.displayName" @ok="attemptCreateIncident"
      :confirm-loading="isSaving" ok-text="Start Incident"
      :ok-button-props="{ props: { disabled: !canCreateIncident } }">
      <a-textarea v-model="incidentDescription" placeholder="Enter required incident notes" rows="6"></a-textarea>
      <!-- <div class="modal-toolbar">
        <span class="is-demo-label">Demo</span>
        <a-switch v-model="isDemo" />
      </div> -->
    </a-modal>

    <a-modal v-model="sosModalVisible" :title="incidentType.displayName" ok-text="Send Now" @ok="createSOS">
      <div class="sos-modal-inner">
        <h3>
          We're about to alert the {{ isDemo ? "Demo" : "SOS" }} responders
        </h3>

        <div class="seconds-until-trigger-wrapper" :style="{ '--p': (100 * secondsUntilTrigger) / 6 }">
          <div class="seconds-until-trigger">
            <div>
              {{ secondsUntilTrigger }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import incidentTypes from "../../../api/incident-types";
import organisations from "../../../helpers/organisations";
import { mapGetters, mapActions } from "vuex";
const _ = require("lodash");

export default {
  props: ["incidentType", "sos", "categories", "lightspeed", "sims", "inWidget"],
  data() {
    return {
      fleshed: null,
      isLoading: false,

      modalVisible: false,
      incidentDescription: "",
      // isDemo: false,

      sosModalVisible: false,
      secondsUntilTrigger: 6,
      triggerIntervalFunction: null,
    };
  },
  watch: {
    sosModalVisible(newVal) {
      if (newVal) {
        let vm = this;
        window.clearInterval(this.triggerIntervalFunction);
        this.triggerIntervalFunction = null;

        this.triggerIntervalFunction = window.setInterval(function () {
          vm.secondsUntilTrigger--;
          if (vm.secondsUntilTrigger == 0) {
            window.clearInterval(vm.triggerIntervalFunction);
            vm.triggerIntervalFunction = null;
            vm.createSOS();
          }
        }, 1000);
      } else {
        window.clearInterval(this.triggerIntervalFunction);
        this.triggerIntervalFunction = null;
      }
    },
  },
  computed: {
    ...mapGetters("helpCaller", {
      selectedOrganisation: "selectedOrganisation",
      isSaving: "isSaving",
      isDemo: "isDemo",
    }),

    canCreateIncident() {
      return this.incidentDescription.trim().length;
    },

    categoryDisplayName() {
      let category = _.find(this.categories, {
        id: this.incidentType.incidentTypeGroupId,
      });
      if (category) {
        return category.displayName;
      }
      return "-";
    },
  },
  created() {
    // this.flesh()
  },
  methods: {
    ...mapActions("helpCaller", {
      callForHelp: "callForHelp",
    }),

    // callForHelpLoop() {
    //   let i = 1;
    //   while (i < 200) {
    //     this.callForHelp({
    //       description: this.incidentDescription,
    //       incidentType: this.incidentType,
    //       isDemo: this.isDemo,
    //     });
    //   }
    // },

    flesh() {
      let vm = this;
      vm.isLoading = true;
      incidentTypes
        .getIncidentType(
          organisations.getOrganisationTenantId(this.selectedOrganisation),
          this.incidentType.id
        )
        .then((r) => {
          vm.fleshed = r.data;
          vm.isLoading = false;
        })
        .catch((e) => {
          // vm.$message.error("Error loading incident types");
          console.log(e);
          vm.fleshed = null;
          vm.isLoading = false;
        });
    },

    handleOptionSelected() {
      if (this.sos) {
        this.openSosModal();
      } else {
        this.openModal();
      }
    },

    openSosModal() {
      this.secondsUntilTrigger = 6;
      this.sosModalVisible = true;
    },

    openModal() {
      this.modalVisible = true;
    },

    attemptCreateIncident() {
      if (!this.canCreateIncident) {
        return false;
      }
      this.callForHelp({
        description: this.incidentDescription,
        incidentType: this.incidentType,
        isDemo: this.isDemo,
        lightspeed: (this.lightspeed == true),
        sims: (this.sims == true)
      });
    },

    createSOS() {
      window.clearInterval(this.triggerIntervalFunction);
      this.triggerIntervalFunction = null;

      this.callForHelp({
        description: this.incidentType.displayName,
        incidentType: this.incidentType,
        isDemo: this.isDemo,
        lightspeed: (this.lightspeed == true),
        sims: (this.sims == true)
      });
    },
  },
};
</script>

<style scoped lang="scss">
.incident-type-option {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #fff;

  &.in-widget {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.lightspeed {
    border: 1px solid #eee;

    &:hover {
      color: #3398c6 !important;
      border: 1px solid #3398c6 !important;
    }
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}

.inner-wrapper {
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
  }
}

.modal-toolbar {
  display: flex;
  display: block;
  margin-top: 15px;

  .is-demo-label {
    margin-right: 10px;
  }
}

.sos {
  color: #00c0b2;
  font-weight: bold;
}

.sos-modal-inner {
  text-align: center;
}

.seconds-until-trigger-wrapper {
  margin-top: 40px;
  margin-bottom: 35px;
  font-size: 100px;
  padding: 7px;
  display: inline-block;
  // background: #5155ea;
  border-radius: 50%;
  position: relative;

  --c: #5155ea;
  --p: 100;
  --b: 5px;

  &:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    inset: 0;
    z-index: 1000;
    background: conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);

    -webkit-mask: radial-gradient(farthest-side,
        #0000 calc(99% - var(--b)),
        #000 calc(100% - var(--b)));
    mask: radial-gradient(farthest-side,
        #0000 calc(99% - var(--b)),
        #000 calc(100% - var(--b)));
  }

  .seconds-until-trigger {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    color: #5155ea;
    z-index: 1001;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
</style>