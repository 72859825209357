<template>
  <div class="latest-announcements-wrapper">
    <create-announcement-modal
    v-if="isModalInitialised"
      :visible="createAnnouncementModalVisible"
      @close-modal="closeCreateAnnouncementModal"
    ></create-announcement-modal>
    <div class="title-wrapper">
      <div class="left">
        <h2 class="title">Announcements</h2>
      </div>
      <div class="right">
        <a-button
          v-if="canCreateAnnouncements"
          class="button-margin-left btn-rounded"
          icon="plus"
          type="primary"
          @click.prevent="openCreateAnnouncementModal"
          >New</a-button
        >
      </div>
    </div>
    <div class="latest-announcements">
      <a-spin v-if="isLoading"></a-spin>

      <!-- Loaded -->
      <div v-else>
        <announcement-list-item
          v-for="announcement in latestAnnouncements"
          :announcement="announcement"
          :key="announcement.id"
          :hide-pin="true"
        ></announcement-list-item>

        <div class="no-results" v-if="latestAnnouncements.length == 0">
          No announcements to show
        </div>
      </div>
      <!-- / Loaded -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AnnouncementListItem from "../Announcements/AnnouncementListItem.vue";
import CreateAnnouncementModal from "../../components/Announcements/CreateAnnouncementModal.vue";
export default {
  components: { AnnouncementListItem, CreateAnnouncementModal },
  data() {
    return {
      isModalInitialised: false,
      createAnnouncementModalVisible: false,
    };
  },
  computed: {
    ...mapGetters("missionControl", {
      canCreateAnnouncements: "canCreateAnnouncements"
    }),

    ...mapGetters("announcements", {
      isLoading: "isLoading",
      announcementsOrdered: "announcementsOrdered",
    }),

    latestAnnouncements() {
      return this.announcementsOrdered.slice(0, 3);
    },
  },
  methods: {
    openCreateAnnouncementModal() {
      if (!this.isModalInitialised) {
        this.isModalInitialised = true;
      }
      let vm = this;
      this.$nextTick(() => {
        vm.createAnnouncementModalVisible = true;
      });
    },

    closeCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.latest-announcements-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-btn-primary {
        background: #3A0BA3;

        &:hover {
            background: #6031C9;
        }
  }


  .title-wrapper {
    flex-shrink: 1;
    display: flex;
    .left {
      flex-grow: 1;
    }
    .right {
      flex-shrink: 1;
    }
  }

  .latest-announcements {
    flex-grow: 1;
    background: #fff;
    padding: 5px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 15px;
  }

  .no-results {
    padding: 15px;
  }
}
</style>