import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/Landing.vue'
import LoggedOut from '../views/LoggedOut.vue'
import Dashboard from '../views/Dashboard.vue'
import idsrvAuth from '../idsrvAuth'
import store from "../store"

console.log(idsrvAuth.authName);

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },

  {
    path: '/logged-out',
    name: 'Logged Out',
    component: LoggedOut
  },

  {
    path: '/sims-login',
    name: 'SIMS Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-login" */ '../views/SimsLogin.vue')
  },

  {
    path: '/landings/sims',
    name: 'SIMS Landing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-landing" */ '../views/Landings/sims.vue')
  },

  {
    path: '/onboarding',
    name: 'Onboarding',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding.vue')
  },

  // Senso
  {
    path: '/senso',
    name: 'Senso',
    component: () => import(/* webpackChunkName: "senso-dashboard" */ '../views/Senso/Dashboard.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/lockdown',
    name: 'Senso Lockdown',
    component: () => import(/* webpackChunkName: "senso-lockdown" */ '../views/Senso/Lockdown.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/announcements',
    name: 'Senso Announcements',
    component: () => import(/* webpackChunkName: "senso-announcements" */ '../views/Senso/Announcements.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/announcements/:tenantId/:id',
    name: 'Senso View Announcement',
    component: () => import(/* webpackChunkName: "senso-show-announcement" */ '../views/Senso/Announcements/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/incidents',
    name: 'Senso Incidents',
    component: () => import(/* webpackChunkName: "senso-incidents" */ '../views/Senso/Incidents.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/incidents/:tenantId/:id',
    name: 'Senso View Incident',
    component: () => import(/* webpackChunkName: "senso-show-incident" */ '../views/Senso/Incidents/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/senso/guidance',
    name: 'Senso Guidance',
    component: () => import(/* webpackChunkName: "senso-guidance" */ '../views/Senso/Guidance.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },

  // Lightspeed
  {
    path: '/lightspeed',
    name: 'Lightspeed',
    component: () => import(/* webpackChunkName: "lightspeed-dashboard" */ '../views/Lightspeed/Dashboard.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/lockdown',
    name: 'Lightspeed Lockdown',
    component: () => import(/* webpackChunkName: "lightspeed-lockdown" */ '../views/Lightspeed/Lockdown.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/announcements',
    name: 'Lightspeed Announcements',
    component: () => import(/* webpackChunkName: "lightspeed-announcements" */ '../views/Lightspeed/Announcements.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/announcements/:tenantId/:id',
    name: 'Lightspeed View Announcement',
    component: () => import(/* webpackChunkName: "lightspeed-show-announcement" */ '../views/Lightspeed/Announcements/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/incidents',
    name: 'Lightspeed Incidents',
    component: () => import(/* webpackChunkName: "lightspeed-incidents" */ '../views/Lightspeed/Incidents.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/call-for-help',
    name: 'Lightspeed Call For Help',
    component: () => import(/* webpackChunkName: "lightspeed-call-for-help" */ '../views/Lightspeed/CallForHelp.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/incidents/:tenantId/:id',
    name: 'Lightspeed View Incident',
    component: () => import(/* webpackChunkName: "lightspeed-show-incident" */ '../views/Lightspeed/Incidents/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/guidance',
    name: 'Lightspeed Guidance',
    component: () => import(/* webpackChunkName: "lightspeed-guidance" */ '../views/Lightspeed/Guidance.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/lightspeed/guidance/:tenantId/:id',
    name: 'Lightspeed Guidance Pack',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lightspeed-guidance-pack" */ '../views/Lightspeed/Guidance/Show.vue')
  },

  // Sims
  {
    path: '/sims',
    name: 'SIMS',
    component: () => import(/* webpackChunkName: "sims" */ '../views/sims/Sims.vue')
  },

  // Sims Simualtor
  {
    path: '/sims-simulator',
    name: 'Sims Simulator',
    component: () => import(/* webpackChunkName: "sims-simulator" */ '../views/sims/SimsSimulator.vue')
  },

  // SIMS Demo
  {
    path: '/sims-demo',
    name: 'sims-demo',
    component: () => import(/* webpackChunkName: "sims-dashboard" */ '../views/sims/Dashboard.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/lockdown',
    name: 'SIMS Lockdown',
    component: () => import(/* webpackChunkName: "sims-lockdown" */ '../views/sims/Lockdown.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/announcements',
    name: 'SIMS Announcements',
    component: () => import(/* webpackChunkName: "sims-announcements" */ '../views/sims/Announcements.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/announcements/:tenantId/:id',
    name: 'SIMS View Announcement',
    component: () => import(/* webpackChunkName: "sims-show-announcement" */ '../views/sims/Announcements/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/chats',
    name: 'SIMS Chats',
    component: () => import(/* webpackChunkName: "sims-chats" */ '../views/sims/Chats.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/chats/:tenantId/:id',
    name: 'SIMS View Chat',
    component: () => import(/* webpackChunkName: "sims-show-chat" */ '../views/sims/Chats/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/incidents',
    name: 'SIMS Incidents',
    component: () => import(/* webpackChunkName: "sims-incidents" */ '../views/sims/Incidents.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/call-for-help',
    name: 'SIMS Call For Help',
    component: () => import(/* webpackChunkName: "sims-call-for-help" */ '../views/sims/CallForHelp.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/incidents/:tenantId/:id',
    name: 'SIMS View Incident',
    component: () => import(/* webpackChunkName: "sims-show-incident" */ '../views/sims/Incidents/Show.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/guidance',
    name: 'SIMS Guidance',
    component: () => import(/* webpackChunkName: "sims-guidance" */ '../views/sims/Guidance.vue'),
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/sims/guidance/:tenantId/:id',
    name: 'SIMS Guidance Pack',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-guidance-pack" */ '../views/sims/Guidance/Show.vue')
  },

  {
    path: '/guest-onboarding-old',
    name: 'Guest Onboarding Landing Old',
    meta: {
      // authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guest-onboarding-landing-old" */ '../views/GuestOnboarding/LandingOld.vue')
  },

  // Guest Onboarding
  {
    path: '/guest-onboarding',
    name: 'Guest Onboarding Landing',
    meta: {
      // authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "guest-onboarding-landing" */ '../views/GuestOnboarding/Landing.vue')
    component: () => import(/* webpackChunkName: "guest-onboarding-calendar" */ '../views/GuestOnboarding/LandingCalendar.vue')
  },

  // Guest Onboarding - Create Account
  {
    path: '/guest-onboarding/create-account',
    name: 'Guest Onboarding - Create Account',
    meta: {
      // authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guest-onboarding-create-account" */ '../views/GuestOnboarding/CreateAccount.vue')
  },

  // Guest Onboarding - Create Org
  {
    path: '/guest-onboarding/create-org',
    name: 'Guest Onboarding - Create Org',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guest-onboarding-create-org" */ '../views/GuestOnboarding/CreateOrg.vue')
  },

  // Sims alias
  // Guest Onboarding
  {
    path: '/sims-onboarding',
    name: 'SIMS Onboarding Landing',
    meta: {
      // authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-onboarding-landing" */ '../views/SimsOnboarding/Landing.vue')
  },

  // Guest Onboarding - Create Account
  {
    path: '/sims-onboarding/create-account',
    name: 'SIMS Onboarding - Create Account',
    meta: {
      // authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-onboarding-create-account" */ '../views/SimsOnboarding/CreateAccount.vue')
  },

  // Guest Onboarding - Create Org
  {
    path: '/sims-onboarding/create-org',
    name: 'Sims Onboarding - Create Org',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sims-onboarding-create-org" */ '../views/SimsOnboarding/CreateOrg.vue')
  },

  // Normal

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      authName: idsrvAuth.authName
    }
  },
  {
    path: '/organisation-not-linked-to-account',
    name: 'Organisation Not Linked To Account',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "organisation-not-linked-to-account" */ '../views/OrganisationNotLinkedToAccount.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/chats',
    name: 'Chats',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "chats" */ '../views/Chats.vue')
  },
  {
    path: '/chats/:tenantId/:id',
    name: 'View Chat',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "view-chat" */ '../views/Chats/Show.vue')
  },
  {
    path: '/incidents',
    name: 'Incidents',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "incidents" */ '../views/Incidents.vue')
  },
  {
    path: '/closed-incidents',
    name: 'Closed Incidents',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "closed-incidents" */ '../views/ClosedIncidents.vue')
  },
  {
    path: '/incidents/:tenantId/:id',
    name: 'View Incident',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "view-incident" */ '../views/Incidents/Show.vue')
  },
  {
    path: '/my-tasks',
    name: 'My Tasks',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tasks" */ '../views/Tasks.vue')
  },
  {
    path: '/guidance',
    name: 'Guidance',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidance" */ '../views/Guidance.vue')
  },
  {
    path: '/guidance/:tenantId/:id',
    name: 'Guidance Pack',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "guidance-pack" */ '../views/Guidance/Show.vue')
  },
  {
    path: '/announcements',
    name: 'Announcements',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "announcements" */ '../views/Announcements.vue')
  },
  {
    path: '/announcements/:tenantId/:id',
    name: 'View Announcement',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "view-announcement" */ '../views/Announcements/Show.vue')
  },
  {
    path: '/start-a-drill',
    name: 'Start A Drill',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "start-a-drill" */ '../views/StartADrill.vue')
  },
  {
    path: '/call-for-help',
    name: 'Call For Help',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "call-for-help" */ '../views/CallForHelp.vue')
  },
  {
    path: '/faqs',
    name: 'FAQs',
    meta: {
      authName: idsrvAuth.authName
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faqs" */ '../views/Faqs.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/admin/stats',
    name: 'Admin - Stats',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-stats" */ '../views/Admin/Stats.vue')
  },
  {
    path: '/admin/managed-groups',
    name: 'Admin - Managed Groups',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-managed-groups" */ '../views/Admin/ManagedGroups.vue')
  },
  {
    path: '/admin/managed-groups/create',
    name: 'Admin - Create Managed Group',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-create-managed-group" */ '../views/Admin/ManagedGroups/Create.vue')
  },
  {
    path: '/admin/managed-groups/:id/edit',
    name: 'Admin - Edit Managed Group',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-edit-managed-group" */ '../views/Admin/ManagedGroups/Edit.vue')
  },
  {
    path: '/admin/announcements',
    name: 'Admin - Announcements',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-announcements" */ '../views/Admin/Announcements.vue')
  },
  {
    path: '/admin/incidents',
    name: 'Admin - Incidents',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-incidents" */ '../views/Admin/Incidents.vue')
  },
  {
    path: '/admin/incidents/:tenantId/:id',
    name: 'Admin - View Incident',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-view-incident" */ '../views/Admin/Incidents/Fetch.vue')
  },
  {
    path: '/admin/archived-incidents',
    name: 'Admin - Archived Incidents',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-archived-incidents" */ '../views/Admin/ArchivedIncidents.vue')
  },
  {
    path: '/admin/responders',
    name: 'Admin - Responders',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders" */ '../views/Admin/Responders.vue')
  },
  {
    path: '/admin/responders/response-teams',
    name: 'Admin - Responders - Response Teams',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders-response-teams" */ '../views/Admin/Responders/ResponseTeams.vue')
  },
  {
    path: '/admin/responders/response-teams/create',
    name: 'Admin - Responders - Create Response Team',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders-create-response-team" */ '../views/Admin/Responders/ResponseTeams/Create.vue')
  },
  {
    path: '/admin/responders/response-teams/:id',
    name: 'Admin - Responders - View Response Team',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders-view-response-team" */ '../views/Admin/Responders/ResponseTeams/Show.vue')
  },
  {
    path: '/admin/responders/response-teams/:id/edit',
    name: 'Admin - Responders - Edit Response Team',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders-edit-response-team" */ '../views/Admin/Responders/ResponseTeams/Edit.vue')
  },
  {
    path: '/admin/rota-dates',
    name: 'Admin - Rota Dates',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-rota-dates" */ '../views/Admin/RotaDates/Index.vue')
  },
  {
    path: '/admin/rota-dates/create',
    name: 'Admin - Rota Dates - Create',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-create-rota-date" */ '../views/Admin/RotaDates/Create.vue')
  },
  {
    path: '/admin/responders/availability',
    name: 'Admin - Responders - Availability',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders-availability" */ '../views/Admin/Responders/Availability.vue')
  },
  {
    path: '/admin/responders/availability/:presenceId/rota',
    name: 'Admin - Responders - Availability - Rota',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders-availability-rota" */ '../views/Admin/Responders/Availability/Rota.vue')
  },
  {
    path: '/admin/responders/availability/:presenceId/rota/create',
    name: 'Admin - Responders - Availability - Rota - Create',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders-availability-rota-create" */ '../views/Admin/Responders/Availability/Rota/Create.vue')
  },
  {
    path: '/admin/responders/availability/:presenceId/exceptions',
    name: 'Admin - Responders - Availability - Exceptions',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders-availability-exceptions" */ '../views/Admin/Responders/Availability/Exceptions.vue')
  },
  {
    path: '/admin/responders/availability/:presenceId/exceptions/create',
    name: 'Admin - Responders - Availability - Exceptions - Create',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders-availability-exceptions-create" */ '../views/Admin/Responders/Availability/Exceptions/Create.vue')
  },
  {
    path: '/admin/responders/availability/:presenceId/exceptions/quick-create',
    name: 'Admin - Responders - Availability - Exceptions - Quic Create',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-responders-availability-exceptions-create" */ '../views/Admin/Responders/Availability/Exceptions/QuickCreate.vue')
  },
  {
    path: '/admin/reporting',
    name: 'Admin - Reporting',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-archived-incidents" */ '../views/Admin/Reporting.vue')
  },
  {
    path: '/admin/reporting/create',
    name: 'Admin - Create Report',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-create-report" */ '../views/Admin/Reporting/Create.vue')
  },
  {
    path: '/admin/integration',
    name: 'Admin - Integration',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-integration" */ '../views/Admin/Integration.vue')
  },
  {
    path: '/admin/integration/smart-buttons',
    name: 'Admin - Smart Buttons',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-smart-buttons" */ '../views/Admin/Integration/SmartButtons.vue')
  },
  {
    path: '/admin/integration/smart-buttons/create',
    name: 'Admin - Create Smart Button',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-create-smart-button" */ '../views/Admin/Integration/SmartButtons/Create.vue')
  },
  {
    path: '/admin/integration/smart-buttons/:id',
    name: 'Admin - View Smart Button',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-view-smart-button" */ '../views/Admin/Integration/SmartButtons/Show.vue')
  },
  {
    path: '/admin/integration/smart-buttons/:id/edit',
    name: 'Admin - Edit Smart Button',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-edit-smart-button" */ '../views/Admin/Integration/SmartButtons/Edit.vue')
  },
  {
    path: '/admin/integration/api-clients',
    name: 'Admin - API Clients',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-api-clients" */ '../views/Admin/Integration/ApiClients.vue')
  },
  {
    path: '/admin/integration/api-clients/create',
    name: 'Admin - Create API Client',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "create-api-client" */ '../views/Admin/Integration/ApiClients/Create.vue')
  },
  {
    path: '/admin/integration/api-clients/:id/edit',
    name: 'Admin - Edit API Client',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-edit-api-client" */ '../views/Admin/Integration/ApiClients/Edit.vue')
  },
  {
    path: '/admin/integration/partners',
    name: 'Admin - Partners',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-partners" */ '../views/Admin/Integration/Partners.vue')
  },
  {
    path: '/admin/integration/partners/create',
    name: 'Admin - Review Partner Integrations',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "create-partner-integration" */ '../views/Admin/Integration/Partners/Create.vue')
  },
  {
    path: '/admin/integration/partners/create/:id',
    name: 'Admin - Review Partner Integration',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "review-partner-integration" */ '../views/Admin/Integration/Partners/Create/ReviewIntegration.vue')
  },
  {
    path: '/admin/integration/partners/:id',
    name: 'Admin - View Partner',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-view-partner" */ '../views/Admin/Integration/Partners/Show.vue')
  },
  {
    path: '/admin/integration/partners/:id/settings',
    name: 'Admin - Edit Partner Settings',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-edit-partner-settings" */ '../views/Admin/Integration/Partners/EditSettings.vue')
  },
  {
    path: '/admin/guidance',
    name: 'Admin - Guidance',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance" */ '../views/Admin/Guidance.vue')
  },
  {
    path: '/admin/guidance/topics',
    name: 'Admin - Guidance - Topics',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-topics" */ '../views/Admin/Guidance/Topics.vue')
  },
  {
    path: '/admin/guidance/topics/create',
    name: 'Admin - Guidance - Create Topic',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-create-topic" */ '../views/Admin/Guidance/Topics/Create.vue')
  },
  {
    path: '/admin/guidance/topics/:id',
    name: 'Admin - Guidance - View Topic',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-view-topic" */ '../views/Admin/Guidance/Topics/Show.vue')
  },
  {
    path: '/admin/guidance/topics/:id/edit',
    name: 'Admin - Guidance - Edit Topic',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-edit-topic" */ '../views/Admin/Guidance/Topics/Edit.vue')
  },
  {
    path: '/admin/guidance/articles',
    name: 'Admin - Guidance - Articles',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-articles" */ '../views/Admin/Guidance/Articles.vue')
  },
  {
    path: '/admin/guidance/articles/create',
    name: 'Admin - Guidance - Create Article',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-create-article" */ '../views/Admin/Guidance/Articles/Create.vue')
  },
  {
    path: '/admin/guidance/articles/:id',
    name: 'Admin - Guidance - View Article',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-view-article" */ '../views/Admin/Guidance/Articles/Show.vue')
  },
  {
    path: '/admin/guidance/articles/:id/edit',
    name: 'Admin - Guidance - Edit Article',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-edit-article" */ '../views/Admin/Guidance/Articles/Edit.vue')
  },
  {
    path: '/admin/guidance/help-videos',
    name: 'Admin - Guidance - Help Videos',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-help-videos" */ '../views/Admin/Guidance/HelpVideos.vue')
  },
  {
    path: '/admin/guidance/help-videos/create',
    name: 'Admin - Guidance - Create Help Video',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-create-help-video" */ '../views/Admin/Guidance/HelpVideos/Create.vue')
  },
  {
    path: '/admin/guidance/help-videos/:id',
    name: 'Admin - Guidance - View Help Video',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-view-help-video" */ '../views/Admin/Guidance/HelpVideos/Show.vue')
  },
  {
    path: '/admin/guidance/help-videos/:id/edit',
    name: 'Admin - Guidance - Edit Help Video',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-edit-help-video" */ '../views/Admin/Guidance/HelpVideos/Edit.vue')
  },
  {
    path: '/admin/guidance/playbooks',
    name: 'Admin - Guidance - Playbooks',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-playbooks" */ '../views/Admin/Guidance/Playbooks.vue')
  },
  {
    path: '/admin/guidance/playbooks/create',
    name: 'Admin - Guidance - Create Playbook',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-create-playbook" */ '../views/Admin/Guidance/Playbooks/Create.vue')
  },
  {
    path: '/admin/guidance/playbooks/:id',
    name: 'Admin - Guidance - View Playbook',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-view-playbook" */ '../views/Admin/Guidance/Playbooks/Show.vue')
  },
  {
    path: '/admin/guidance/playbooks/:id/edit',
    name: 'Admin - Guidance - Edit Playbook',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-edit-playbook" */ '../views/Admin/Guidance/Playbooks/Edit.vue')
  },
  {
    path: '/admin/guidance/downloads',
    name: 'Admin - Guidance - Downloads',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-downloads" */ '../views/Admin/Guidance/Downloads.vue')
  },
  {
    path: '/admin/guidance/downloads/create',
    name: 'Admin - Guidance - Create Download',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-create-download" */ '../views/Admin/Guidance/Downloads/Create.vue')
  },
  {
    path: '/admin/guidance/downloads/:id',
    name: 'Admin - Guidance - View Download',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-view-download" */ '../views/Admin/Guidance/Downloads/Show.vue')
  },
  {
    path: '/admin/guidance/downloads/:id/edit',
    name: 'Admin - Guidance - Edit Download',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-edit-download" */ '../views/Admin/Guidance/Downloads/Edit.vue')
  },
  {
    path: '/admin/guidance/external-links',
    name: 'Admin - Guidance - External Links',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-external-links" */ '../views/Admin/Guidance/ExternalLinks.vue')
  },
  {
    path: '/admin/guidance/external-links/create',
    name: 'Admin - Guidance - Create External Link',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-guidance-create-external-link" */ '../views/Admin/Guidance/ExternalLinks/Create.vue')
  },
  {
    path: '/admin/guidance/external-links/:id',
    name: 'Admin - Guidance - View External Link',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-view-external-link" */ '../views/Admin/Guidance/ExternalLinks/Show.vue')
  },
  {
    path: '/admin/guidance/external-links/:id/edit',
    name: 'Admin - Guidance - Edit External Link',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-edit-external-link" */ '../views/Admin/Guidance/ExternalLinks/Edit.vue')
  },
  {
    path: '/admin/users',
    name: 'Admin - Users',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-users" */ '../views/Admin/Users.vue')
  },
  {
    path: '/admin/users/invite',
    name: 'Admin - Invite Users',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-invite-users" */ '../views/Admin/Users/Invite.vue')
  },
  {
    path: '/admin/users/imports',
    name: 'Admin - User Imports',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-user-imports" */ '../views/Admin/Users/Imports.vue')
  },
  {
    path: '/admin/users/imports/create',
    name: 'Admin - Create User Import',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-create-user-import" */ '../views/Admin/Users/Imports/Create.vue')
  },
  {
    path: '/admin/users/manage',
    name: 'Admin - Users - Manage',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-manage-users" */ '../views/Admin/Users/Manage.vue')
  },
  {
    path: '/admin/users/manage/email',
    name: 'Admin - Users - Email Invites',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-manage-users-email-user" */ '../views/Admin/Users/Manage/EmailInvites.vue')
  },
  {
    path: '/admin/users/manage/create',
    name: 'Admin - Users - Create User',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-manage-users-create-user" */ '../views/Admin/Users/Manage/Create.vue')
  },
  {
    path: '/admin/users/manage/:id',
    name: 'Admin - Manage Users - View User',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-manage-users-view-user" */ '../views/Admin/Users/Manage/Show.vue')
  },
  {
    path: '/admin/users/manage/:id/edit',
    name: 'Admin - Manage Users - Edit User',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-manage-users-edit-user" */ '../views/Admin/Users/Manage/Edit.vue')
  },
  {
    path: '/admin/settings',
    name: 'Admin - Settings',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings" */ '../views/Admin/Settings.vue')
  },
  {
    path: '/admin/settings/windows-installs',
    name: 'Admin - Settings - Windows Installs',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-windows-installs" */ '../views/Admin/Settings/WindowsInstalls.vue')
  },
  {
    path: '/admin/settings/quick-messages',
    name: 'Admin - Settings - Quick Messages',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-quick-messages" */ '../views/Admin/Settings/QuickMessages.vue')
  },
  {
    path: '/admin/settings/quick-messages/create',
    name: 'Admin - Settings - Create Quick Message',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-create-quick-message" */ '../views/Admin/Settings/QuickMessages/Create.vue')
  },
  {
    path: '/admin/settings/quick-messages/:id/edit',
    name: 'Admin - Settings - Edit Quick Message',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-edit-quick-message" */ '../views/Admin/Settings/QuickMessages/Edit.vue')
  },
  {
    path: '/admin/settings/playbooks',
    name: 'Admin - Settings - Playbooks',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-playbooks" */ '../views/Admin/Settings/Playbooks.vue')
  },
  {
    path: '/admin/settings/playbooks/create',
    name: 'Admin - Settings - Create Playbook',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-create-playbook" */ '../views/Admin/Settings/Playbooks/Create.vue')
  },
  {
    path: '/admin/settings/playbooks/:id',
    name: 'Admin - Settings - View Playbook',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-view-playbook" */ '../views/Admin/Settings/Playbooks/Show.vue')
  },
  {
    path: '/admin/settings/playbooks/:id/edit',
    name: 'Admin - Settings - Edit Playbook',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-edit-playbook" */ '../views/Admin/Settings/Playbooks/Edit.vue')
  },
  {
    path: '/admin/settings/locations',
    name: 'Admin - Settings - Locations',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-locations" */ '../views/Admin/Settings/Locations.vue')
  },
  {
    path: '/admin/settings/locations/create',
    name: 'Admin - Settings - Create Location',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-create-location" */ '../views/Admin/Settings/Locations/Create.vue')
  },
  {
    path: '/admin/settings/locations/:id/edit',
    name: 'Admin - Settings - Edit Location',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-edit-location" */ '../views/Admin/Settings/Locations/Edit.vue')
  },
  {
    path: '/admin/settings/incident-type-groups',
    name: 'Admin - Settings - Incident Type Groups',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-incident-type-groups" */ '../views/Admin/Settings/IncidentTypeGroups.vue')
  },
  {
    path: '/admin/settings/incident-type-groups/:id/edit',
    name: 'Admin - Settings - Edit Incident Type Group',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-edit-incident-type-group" */ '../views/Admin/Settings/IncidentTypeGroups/Edit.vue')
  },
  {
    path: '/admin/settings/incident-type-groups/create',
    name: 'Admin - Settings - Create Incident Type Group',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-create-incident-type-group" */ '../views/Admin/Settings/IncidentTypeGroups/Create.vue')
  },
  {
    path: '/admin/settings/incident-types',
    name: 'Admin - Settings - Incident Types',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-incident-types" */ '../views/Admin/Settings/IncidentTypes.vue')
  },
  {
    path: '/admin/settings/incident-types/create',
    name: 'Admin - Settings - Create Incident Type',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-create-incident-type" */ '../views/Admin/Settings/IncidentTypes/Create.vue')
  },
  {
    path: '/admin/settings/incident-types/:id',
    name: 'Admin - Settings - Show Incident Type',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-show-incident-type" */ '../views/Admin/Settings/IncidentTypes/Show.vue')
  },
  {
    path: '/admin/settings/incident-types/:id/edit',
    name: 'Admin - Settings - Edit Incident Type',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-edit-incident-type" */ '../views/Admin/Settings/IncidentTypes/Edit.vue')
  },
  {
    path: '/admin/settings/emergency-types',
    name: 'Admin - Settings - Emergency Types',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-emergency-types" */ '../views/Admin/Settings/EmergencyTypes.vue')
  },
  {
    path: '/admin/settings/emergency-types/create',
    name: 'Admin - Settings - Create Emergency Type',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-create-emergency-type" */ '../views/Admin/Settings/EmergencyTypes/Create.vue')
  },
  {
    path: '/admin/settings/emergency-types/:id/edit',
    name: 'Admin - Settings - Edit Emergency Type',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-settings-edit-emergency-type" */ '../views/Admin/Settings/EmergencyTypes/Edit.vue')
  },
  {
    path: '/admin/organisational-settings',
    beforeEnter() {
      let env = store.getters['missionControl/env'];
      if (env == 'live') {
        window.open("https://portal.teamsos.co.uk/app");
      }
      if (env == 'staging') {
        window.open("https://portal.teamsos.co.uk/app");
      }
      if (env == 'testing') {
        window.open("https://portal.testing.teamsos.co.uk/app");
      }
    }
  },
  {
    path: '/super-admin/leads',
    meta: {
      authName: idsrvAuth.authName,
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "super-admin" */ '../views/SuperAdmin/Leads.vue')
  },
  {
    path: '/platform-admin/sso',
    meta: {
      authName: idsrvAuth.authName,
      platformAdmin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "platform-admin-sso" */ '../views/PlatformAdmin/SSO.vue')
  },
  {
    path: '/platform-admin/sso',
    meta: {
      authName: idsrvAuth.authName,
      platformAdmin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "platform-admin-sso" */ '../views/PlatformAdmin/SSO.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Requires non hidden presences guard
// router.beforeEach((to, from, next) => {
//   if (!store.getters['missionControl/isLoggedIn']) {
//     next()
//   }
//   if (to.matched.some(record => record.meta.requiresNonHiddenPresences)) {
//     if (store.getters['missionControl/hasNonHiddenPresences']) {
//       next()
//       return
//     }
//     next('/admin')
//   } else {
//     next()
//   }
// });

// Admin Guard
router.beforeEach((to, from, next) => {
  // if (!store.getters['missionControl/isLoggedIn']) {
  // next()
  // }
  if (to.matched.some(record => record.meta.admin)) {
    if (!store.getters['missionControl/isLoggedIn']) {
      next()
      return
    }
    if (store.getters['missionControl/canViewAdminArea']) {
      next()
      return
    } else {
      next('/')
      return
    }
  } else {
    next()
  }
})


// Platform Admin Guard
router.beforeEach((to, from, next) => {
  // if (!store.getters['missionControl/isLoggedIn']) {
  // next()
  // }
  if (to.matched.some(record => record.meta.platformAdmin)) {
    if (!store.getters['missionControl/isLoggedIn']) {
      next()
      return
    }
    if (store.getters['missionControl/isPlatformAdmin']) {
      next()
      return
    } else {
      next('/')
      return
    }
  } else {
    next()
  }
})

idsrvAuth.useRouter(router)

export default router
