<template>
  <div id="app" :class="{ 'is-onboarding': isOnboarding }">
    <signal-r-disconnected-overlay></signal-r-disconnected-overlay>
    <a-layout v-if="$oidc.isAuthenticated && !sensoLayout && !lightspeedLayout && !simsLayout && !guestOnboardingLayout"
      id="primary-layout">
      <update-banner></update-banner>
      <notification-banner></notification-banner>

      <feedback-modal :version="latestAppVersion" :visible="showFeedbackModal"
        @close="closeFeedbackModal"></feedback-modal>

      <help-and-support-modal :visible="showHelpAndSupportModal"
        @close="closeHelpAndSupportModal"></help-and-support-modal>

      <change-avatar-modal @presence-updated="incrementPresenceThumbnailKey" :visible="showChangeAvatarModal"
        @close="closeChangeAvatarModal"></change-avatar-modal>

      <tsos-widget v-if="false"></tsos-widget>

      <a-layout-header class="header">
        <div class="logo">
          <img src="/logo-new.png" alt="Logo" />
        </div>

        <div class="actions-right">
          <!-- <help-and-support-button-and-modal></help-and-support-button-and-modal> -->
          <describe-this-page-button-and-modal
            v-if="!isLoading && shouldShowDescribeThisPage"></describe-this-page-button-and-modal>


          <a-button v-if="!isLoading && !isOnboarding && onboardingOrgsToShow.length"
            @click.prevent="$router.push('/onboarding')" class="onboarding-button btn-rounded">Finish
            Onboarding</a-button>

          <update-personal-availability-button-and-modal
            v-if="!isLoading && hasNonHiddenPresences && !isOnboarding"></update-personal-availability-button-and-modal>

          <search-button-and-modal v-if="!isLoading && hasNonHiddenPresences && !isOnboarding"></search-button-and-modal>


          <a-button v-if="!isLoading && hasNonHiddenPresences && !isOnboarding" @click.prevent="callForHelp"
            class="call-for-help-button btn-rounded">
            <img src="@/assets/shield.png" width="15" style="margin-right: 10px;" />
            Call For Help</a-button>

          <a-dropdown :trigger="['click']" v-if="$oidc.isAuthenticated">
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <presence-thumbnail :key="presenceThumbnailKey" v-if="userPresence"
                style="display: inline-block; margin-right: 8px" :presence-id="userPresence.id"
                :owner-id="userPresence.ownerId"></presence-thumbnail>
              {{ userDisplayName }}
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="#" @click.prevent="changePassword">Change Password</a>
              </a-menu-item>
              <a-menu-item>
                <a href="#" @click.prevent="changeAvatar">Change Avatar</a>
              </a-menu-item>
              <a-menu-item>
                <a href="#" @click.prevent="twoFactorAuthentication">Two Factor Authentication</a>
              </a-menu-item>
              <a-menu-item>
                <a href="#" @click.prevent="signOut">Logout</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-sider @mouseover="handleMouseoverNavbarEvent" @mouseout="handleMouseoutNavbarEvent"
          v-if="!isLoading && !isOnboarding" v-model="menuCollapsed" :collapsible="false" width="235"
          style="background: #fff">
          <a-menu class="primary-sidebar" mode="inline" :style="{
            height: '100%',
            marginTop: '15px',
            paddingBottom: '25px',
            display: 'flex',
            flexDirection: 'column',
            borderRight: 0,
          }">
            <!-- Menu A - has non-hidden presences -->
            <a-menu v-if="hasNonHiddenPresences" mode="inline" @click="handleMenuItemClicked"
              :selected-keys="[selectedMenuKey]" class="top-menu not-admin-menu" :style="{ flexGrow: 1 }">
              <a-menu-item key="dashboard"><a-icon :style="{ fontSize: '20px' }" type="home" theme="outlined" />
                <span>Home</span></a-menu-item>
              <a-menu-item key="chats" v-if="!isChatDisabledForAllOrganisations">
                <a-icon :style="{ fontSize: '20px' }" type="message" theme="outlined" />
                <span>
                  Messages
                  <!-- <span class="" v-if="unreadChats && unreadChats.length > 0"
                  >({{ unreadChats.length }})</span
                > -->
                </span>
                <a-badge :count="unreadChatCount"> </a-badge>
              </a-menu-item>
              <a-menu-item key="incidents"><a-icon :style="{ fontSize: '20px' }" type="warning" theme="outlined" />
                <span> Incidents </span>
                <a-badge :count="unreadIncidentCount"> </a-badge>
              </a-menu-item>
              <a-menu-item key="announcements"><a-icon :style="{ fontSize: '20px' }" type="notification"
                  theme="outlined" />
                <span> Announcements </span>
                <a-badge :count="unreadAnnouncementCount"> </a-badge>
              </a-menu-item>
              <a-menu-item key="guidance" v-if="guidanceModeEnabled"><a-icon :style="{ fontSize: '20px' }" type="book"
                  theme="outlined" />
                <span>Guidance</span></a-menu-item>
              <a-menu-item key="my-tasks">
                <a-icon :style="{ fontSize: '20px' }" type="unordered-list" />
                <span> My Tasks </span>
                <a-badge :count="incompleteTaskCount"> </a-badge>
              </a-menu-item>
            </a-menu>
            <a-menu v-if="hasNonHiddenPresences" mode="inline" @click="handleMenuItemClicked"
              :selected-keys="[selectedMenuKey]" class="bottom-menu not-admin-menu" :style="{ flexShrink: 1 }">
              <a-menu-item key="admin" v-if="organisationsIAmAnAdminFor.length && canViewAdminArea"><a-icon
                  :style="{ fontSize: '20px' }" type="setting" theme="outlined" />
                <span>Admin</span></a-menu-item>

              <a-menu-item key="start-a-drill"><a-icon :style="{ fontSize: '20px' }" type="info-circle"
                  theme="outlined" />
                <span>Start A Drill</span></a-menu-item>
              <a-menu-item key="feedback"><a-icon :style="{ fontSize: '20px' }" type="heart" theme="outlined" />
                <span>Feedback</span></a-menu-item>
              <a-menu-item key="help-and-support"><a-icon :style="{ fontSize: '20px' }" type="phone" theme="outlined" />
                <span>Help And Support</span></a-menu-item>
              <a-menu-item key="app-version" class="app-version">
                <a-icon :style="{ fontSize: '20px' }" type="cloud" theme="outlined" />
                <span>App version {{ latestAppVersion }} </span>
              </a-menu-item>
            </a-menu>
            <!-- Menu A - has non-hidden presences -->

            <!-- Menu B - no non-hidden presences -->
            <a-menu v-if="!hasNonHiddenPresences" mode="inline" @click="handleMenuItemClicked"
              :selected-keys="[selectedMenuKey]" class="top-menu admin-menu" :style="{ flexGrow: 1 }">
              <a-menu-item key="admin"><a-icon :style="{ fontSize: '20px' }" type="home" theme="outlined" />
                <span>Home</span></a-menu-item>
              <a-menu-item key="admin/managed-groups"><a-icon :style="{ fontSize: '20px' }" type="usergroup-add"
                  theme="outlined" />
                <span>Managed Groups</span></a-menu-item>
              <a-menu-item key="admin/announcements"><a-icon :style="{ fontSize: '20px' }" type="notification"
                  theme="outlined" />
                <span>Announcements</span></a-menu-item>
              <a-menu-item key="admin/incidents"><a-icon :style="{ fontSize: '20px' }" type="warning" theme="outlined" />
                <span>Active Incidents</span></a-menu-item>
              <a-menu-item key="admin/archived-incidents"><a-icon :style="{ fontSize: '20px' }" type="close-circle"
                  theme="outlined" />
                <span>Closed Incidents</span></a-menu-item>
              <a-menu-item key="admin/reporting"><a-icon :style="{ fontSize: '20px' }" type="line-chart"
                  theme="outlined" />
                <span>Reporting</span></a-menu-item>
              <a-menu-item key="admin/guidance"><a-icon :style="{ fontSize: '20px' }" type="book" theme="outlined" />
                <span>Guidance</span></a-menu-item>
              <a-menu-item key="admin/integration"><a-icon :style="{ fontSize: '20px' }" type="link" theme="outlined" />
                <span>Integration</span></a-menu-item>
              <a-menu-item key="admin/users"><a-icon :style="{ fontSize: '20px' }" type="user" theme="outlined" />
                <span>Users</span></a-menu-item>
              <a-menu-item key="admin/responders"><a-icon :style="{ fontSize: '20px' }" type="bell" theme="outlined" />
                <span>Responders</span></a-menu-item>
            </a-menu>
            <a-menu v-if="!hasNonHiddenPresences" mode="inline" @click="handleMenuItemClicked"
              :selected-keys="[selectedMenuKey]" class="bottom-menu admin-menu" :style="{ flexShrink: 1 }">
              <a-menu-item key="admin/organisational-settings"><a-icon :style="{ fontSize: '20px' }" type="setting"
                  theme="outlined" />
                <span>Org Settings</span></a-menu-item>
              <a-menu-item key="feedback"><a-icon :style="{ fontSize: '20px' }" type="heart" theme="outlined" />
                <span>Feedback</span></a-menu-item>
              <a-menu-item key="app-version" class="app-version">
                <a-icon :style="{ fontSize: '20px' }" type="cloud" theme="outlined" />
                <span>App version {{ latestAppVersion }} </span>
              </a-menu-item>
            </a-menu>
            <!-- / Menu B -->
          </a-menu>
        </a-layout-sider>
        <a-layout :style="{
          padding: noPadding || isLoading ? 0 : '30px 40px',
          background: '#f7f8fa',
        }">
          <a-layout-content>
            <router-view v-if="!isLoading" />
            <loading-context v-if="isLoading"></loading-context>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
    <!-- Guest Onboarding -->
    <a-layout v-if="guestOnboardingLayout">
      <router-view />
    </a-layout>
    <!-- / Guest Onboarding -->
    <!-- Senso -->
    <a-layout v-if="$oidc.isAuthenticated && sensoLayout" id="senso-layout">
      <router-view />
    </a-layout>
    <!-- / Senso -->
    <!-- Lightspeed -->
    <a-layout v-if="$oidc.isAuthenticated && lightspeedLayout" id="lightspeed-layout">
      <router-view />
    </a-layout>
    <!-- / Lightspeed -->
    <!-- sims -->
    <a-layout v-if="$oidc.isAuthenticated && simsLayout" id="sims-layout">
      <router-view />
    </a-layout>
    <!-- / sims -->
    <router-view v-if="!$oidc.isAuthenticated && !guestOnboardingLayout" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FeedbackModal from "./components/FeedbackModal.vue";
import PresenceThumbnail from "./components/Presences/PresenceThumbnail.vue";
import SignalRDisconnectedOverlay from "./components/SignalRDisconnectedOverlay.vue";
import UpdateBanner from "./components/UpdateBanner.vue";
import NotificationBanner from "./components/NotificationBanner.vue";
import ChangeAvatarModal from "./components/ChangeAvatarModal.vue";
import eventBus from "./event-bus";
import SignalRHandler from "./mixins/SignalRHandler";
import LoadingContext from "./views/LoadingContext.vue";
// import HelpAndSupportButtonAndModal from "./components/HelpAndSupportButtonAndModal";
import HelpAndSupportModal from "./components/HelpAndSupportModal.vue";
import DescribeThisPageButtonAndModal from "./components/DescribeThisPageButtonAndModal.vue";
import UpdatePersonalAvailabilityButtonAndModal from "./components/UpdatePersonalAvailabilityButtonAndModal.vue";
import SearchButtonAndModal from "./components/SearchButtonAndModal.vue";

import SmartBanner from "smart-app-banner";
import "smart-app-banner/dist/smart-app-banner.css";

import TsosWidget from "./components/TsosWidget/TsosWidget.vue";

export default {
  data() {
    return {
      selectedMenuKey: "dashboard",
      showFeedbackModal: false,
      showHelpAndSupportModal: false,
      showChangeAvatarModal: false,
      windowWidth: window.innerWidth,
      latestAppVersion: null,
      presenceThumbnailKey: 1,

      hoveringOnNavbar: false,

      deferredPrompt: null,
    };
  },

  components: {
    SignalRDisconnectedOverlay,
    UpdateBanner,
    NotificationBanner,
    FeedbackModal,
    PresenceThumbnail,
    ChangeAvatarModal,
    LoadingContext,
    HelpAndSupportModal,
    DescribeThisPageButtonAndModal,
    UpdatePersonalAvailabilityButtonAndModal,
    SearchButtonAndModal,
    TsosWidget
  },

  mixins: [SignalRHandler],

  computed: {
    ...mapGetters("missionControl", {
      userDisplayName: "userDisplayName",
      userPresence: "userPresence",
      guidanceModeEnabled: "guidanceModeEnabled",
      isLoggedIn: "isLoggedIn",
      isPlatformAdmin: "isPlatformAdmin",
      isLoading: "isLoading",
      organisations: 'organisations',
      organisationsIAmAnAdminFor: "organisationsIAmAnAdminFor",
      canViewAdminArea: "canViewAdminArea",
      hasNonHiddenPresences: "hasNonHiddenPresences",
      isChatDisabledForAllOrganisations: "isChatDisabledForAllOrganisations",
      organisationsIAmAnAdminForWithIncompleteOnboarding: "organisationsIAmAnAdminForWithIncompleteOnboarding"
    }),

    ...mapGetters("onboarding", {
      onboardingOrgsToShow: "onboardingOrgsToShow"
    }),

    ...mapGetters("notifier", {
      windowFocusStatus: "windowFocusStatus",
    }),

    ...mapGetters("incidents", {
      unreadIncidentCount: "unreadIncidentCount",
    }),

    ...mapGetters("chats", {
      unreadChatCount: "unreadChatCount",
    }),

    ...mapGetters("tasks", {
      incompleteTaskCount: "incompleteTaskCount",
    }),

    ...mapGetters("announcements", {
      unreadAnnouncementCount: "unreadAnnouncementCount",
    }),

    shouldShowDescribeThisPage() {
      return this.$route.query.describe && Boolean(this.$route.query.describe) == true;
    },



    sensoLayout() {
      if (this.$route && this.$route.name) {
        return this.$route.name.toLowerCase().includes('senso')
      }
      return false;
    },

    lightspeedLayout() {
      if (this.$route && this.$route.name) {
        return this.$route.name.toLowerCase().includes('lightspeed')
      }
      return false;
    },

    simsLayout() {
      if (this.$route && this.$route.name) {
        return this.$route.name.toLowerCase().includes('sims')
      }
      return false;
    },

    guestOnboardingLayout() {
      if (this.$route && this.$route.name) {
        return this.$route.name.toLowerCase().includes('guest onboarding')
      }
      return false;
    },

    noPadding() {
      return ["View Incident", "View Chat", "View Announcement"].includes(
        this.$route.name
      );
    },

    isOnboarding() {
      return this.$route.name == "Onboarding";
    },

    menuCollapsed() {
      if (this.hoveringOnNavbar) {
        return false;
      }

      let routesToKeepOpen = [
        "Dashboard",
        "Chats",
        "Incidents",
        "Closed Incidents",
        "Announcements",
        "Guidance",
        "My Tasks",
      ];

      if (routesToKeepOpen.includes(this.$route.name)) {
        return false;
      }

      return true;

      // let collapseFromRoute = [
      //   "Dashboard",
      //   "View Incident",
      //   "View Chat",
      //   "View Announcement",
      // ].includes(this.$route.name);

      // let collapseFromScreenSize = this.windowWidth < 1200;

      // return collapseFromRoute || collapseFromScreenSize;
    },
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });

    this.selectedMenuKey = this.$router.currentRoute.path.split("/")[1];
    if (this.$oidc.isAuthenticated) {
      this.launch();
    }

    eventBus.$on("user-loaded", () => {
      this.bootIntercom();

      if (!this.isLoggedIn) {
        this.loggedIn();
        this.launch();
      } else {
        console.log("user loaded received but already logged in");
      }
    });

    let vm = this;
    eventBus.$on("idsrv-logout", () => {
      vm.signOut();
    });

    // eventBus.$on("user-signed-out", () => {
    // this.$router.push("/");
    // });
  },

  created() {
    this.listenToSignalRMessages();
    this.watchForegroundBackgroundChange();
    // this.browserNotifySignalREvents();
    let vm = this;
    eventBus.$on("appVersion", (version) => {
      vm.latestAppVersion = version;
    });
    this.alertIfBrowserNotificationsNotAllowed();
    // this.listenForAndroidBeforeInstallPrompt();
    this.initialiseMobileAppSmartBanner();

    if (this.loggedIn) {
      this.bootIntercom();
    }
  },

  watch: {
    $route(to) {
      this.selectedMenuKey = to.path.split("/")[1];
      console.log("You are travelling to:");
      console.log(to);
      if (to.name !== "View Chat") {
        this.clearChatViewer();
      }
      if (to.name !== "View Incident") {
        this.clearIncidentViewer();
      }
      if (to.name !== "View Announcement") {
        this.clearAnnouncementViewer();
      }

      // Check if logged in && needs to onboard
      if (this.$oidc.isAuthenticated) {
        if (this.onboardingOrgsToShow && this.onboardingOrgsToShow.length > 0 && this.onboardingOrgsToShow.length == this.organisations.length && to.name !== "Onboarding" && !this.isPlatformAdmin) {
          this.$router.push("/onboarding");
        }
      }
    },
  },

  methods: {
    ...mapActions("missionControl", {
      launch: "launch",
      loggedIn: "loggedIn",
      loggedOut: "loggedOut",
    }),

    ...mapActions("notifier", {
      setBackground: "setBackground",
      setForeground: "setForeground",
    }),

    ...mapActions("chats", {
      loadChats: "loadChats",
    }),

    ...mapActions("chatViewer", {
      clearChatViewer: "clearChatViewer",
    }),

    ...mapActions("incidentViewer", {
      clearIncidentViewer: "clearIncidentViewer",
    }),

    ...mapActions("announcementViewer", {
      clearAnnouncementViewer: "clearAnnouncementViewer",
    }),

    bootIntercom() {
      this.$intercom.boot({
        user_id: this.$oidc.user.profile.sub,
        name: this.userDisplayName
      });
    },

    alertIfBrowserNotificationsNotAllowed() {
      try {
        this.$notification.requestPermission().then((r) => {
          console.log("BROWSER NOTIFICATION PERMISSION:");
          console.log("===================================");
          console.log(r);
          if (r == "denied") {
            this.$message.warning("Please enable browser notifications");
          }
        }); // Prints "granted", "denied" or "default"
      } catch (e) {
        console.log(e);
      }
    },

    watchForegroundBackgroundChange() {
      // Let's check every 500ms
      window.setInterval(() => {
        if (document.hasFocus()) {
          // console.log('foreground');
          if (this.windowFocusStatus !== "foreground") {
            this.setForeground();
          }
        } else {
          // console.log('background');
          if (this.windowFocusStatus !== "background") {
            this.setBackground();
          }
        }
      }, 500);
    },

    initialiseMobileAppSmartBanner() {
      new SmartBanner({
        daysHidden: 0, // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 0, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        title: "teamSOS Mobile",
        author: "teamSOS",
        button: "Download",
        store: {
          ios: "On the App Store",
          android: "In Google Play",
        },
        price: {
          ios: "FREE",
          android: "FREE",
        },
        // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        // , icon: '' // full path to icon image if not using website icon image
      });
    },

    // listenToSignalRControlMessage() {
    //   let vm = this;
    //   this.$signalR.on("ControlMessage", (controlMessage) => {
    //     if (controlMessage.messageType === 11) {
    //       vm.loadChats();
    //     }
    //   });
    // },

    handleMenuItemClicked(e) {
      if (e.key == "feedback") {
        this.openFeedbackModal();
        return false;
      }
      if (e.key == "help-and-support") {
        this.openHelpAndSupportModal();
        return false;
      }
      if (e.key == "app-version") {
        return false;
      }
      let navigateTo = e.key;
      this.$router.push("/" + navigateTo);
    },

    openFeedbackModal() {
      this.showFeedbackModal = true;
    },

    closeFeedbackModal() {
      this.showFeedbackModal = false;
    },

    openHelpAndSupportModal() {
      this.showHelpAndSupportModal = true;
    },

    closeHelpAndSupportModal() {
      this.showHelpAndSupportModal = false;
    },

    callForHelp() {
      this.$router.push("/call-for-help");
    },

    signOut() {
      window.isLoggingOut = true;
      this.$router.push("/logged-out");
      this.$oidc.signOut();
      this.$signalR.stop();
      this.loggedOut();
    },

    listenForAndroidBeforeInstallPrompt() {
      let vm = this;
      window.addEventListener("beforeinstallprompt", (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        vm.deferredPrompt = e;
        // Update UI notify the user they can add to home screen
        // btnAdd.style.display = "block";

        vm.deferredPrompt.prompt();
      });
    },

    browserNotifySignalREvents() {
      // let vm = this;
      // vm.$signalR.on("NewChatEntry", (newChatEntry) => {
      //   console.log('New chat entry (browser notify signal r events)');
      //   console.log(newChatEntry);
      //   vm.$notification.show(
      //     "New Message",
      //     {
      //       body: "Message received",
      //     },
      //     {}
      //   );
      // });
    },

    changePassword() {
      window.open(
        window.authActiveProfileAuthority + "/Identity/Account/ChangePassword",
        "_blank"
      );
    },

    twoFactorAuthentication() {
      window.open(
        window.authActiveProfileAuthority +
        "/Identity/Account/TwoFactorAuthentication",
        "_blank"
      );
    },

    changeAvatar() {
      this.showChangeAvatarModal = true;
    },

    closeChangeAvatarModal() {
      this.showChangeAvatarModal = false;
    },

    incrementPresenceThumbnailKey() {
      this.presenceThumbnailKey += 1;
    },

    handleMouseoverNavbarEvent() {
      this.hoveringOnNavbar = true;
    },

    handleMouseoutNavbarEvent() {
      this.hoveringOnNavbar = false;
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app,
#primary-layout {
  height: 100%;
  overflow: hidden;
}

#primary-layout {
  .ant-layout-header {
    padding-left: 20px;
    padding-right: 20px;
    // background: #5155ea;
    // background: #5155ea;
    background: #3A0BA3;

    .actions-right {
      .help-and-support-button-and-modal {
        margin-right: 20px;
      }

      .call-for-help-button {
        margin-right: 25px;
        font-weight: 500;
      }

      .onboarding-button {
        margin-right: 20px;
        font-weight: 500;
      }

      float: right;

      a {
        color: #fff;
      }
    }
  }

  .logo {
    height: 30px;
    float: left;

    img {
      max-height: 100%;
    }
  }

  .ant-menu-inline-collapsed {
    .ant-menu-item .anticon {
      position: absolute;
      left: 0;
      right: 0;
      top: 5px;
    }
  }

  .primary-sidebar {
    .ant-menu {
      border-right: none;
    }

    .not-admin-menu {
      .ant-menu-item {
        height: 47px;
        line-height: 47px;
      }
    }

    .ant-menu-item {
      border-radius: 8px;
      width: 93%;
      margin-left: auto;
      margin-right: auto;
      border-right: none;

      color: rgba(0, 0, 0, 0.85);
      font-size: 15px;

      .ant-badge {
        margin-left: 8px;
        position: absolute;
        right: 9px;
        top: 14px;
      }

      &:hover,
      &.ant-menu-item-selected {
        background: rgba(81, 85, 234, 0.1);
      }

      &.ant-menu-item-selected::after {
        opacity: 0;
      }
    }
  }
}

.app-version {

  i,
  span {
    color: rgba(0, 0, 0, 0.4) !important;
    // color: rgb(0, 192, 178) !important;
  }

  span {
    font-size: 11px !important;
    position: absolute;
    top: 0;
  }
}

.is-onboarding {
  #primary-layout {
    .ant-layout-header {
      background: #3A0BA3;
    }

    .ant-layout {
      // background: #3A0BA3 !important;
    }
  }
}
</style>
